
import CloudFun, {
  defineComponent,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import AddressSelectBox from "@/components/address-select-box/Main.vue"
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AddressSelectBox,
    VueQrcode
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const panelVisible = reactive({
      introduction: true,
      content: true
    })

    const gridOptions: GridOptions = {
      stripe: false,
      title: "商家",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "商家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Area.Name",
          title: "風景區",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Category.Name",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Address.City",
          title: "縣市",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
          // formatter: ({ cellValue }) => formatDate(cellValue, "yyyy/MM/dd")
        },
        {
          field: "Address.District",
          title: "區",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
            return model.dispatch("store/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("store/query") : undefined,
        save: model ? params => model.dispatch("store/save", params) : undefined
      },
      modalConfig: { showFooter: true, width: "80%", height: "80%" }
      //   treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleAlign: "right",
      titleWidth: 100,
      // size: "medium",
      items: [
        {
          field: "Name",
          title: "店家名稱",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", readOnly: true }
          }
        },
        {
          field: "ShortName",
          title: "簡稱",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入聯絡簡稱" }
          }
        },
        {
          field: "TaxId",
          title: "統一編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入統一編號" }
          }
        },
        {
          field: "CategoryId",
          title: "分類",
          span: 12,
          slots: { default: "column-category-id" }
        },
        {
          field: "AreaId",
          title: "風景區",
          span: 12,
          slots: { default: "column-area-id" }
        },
        {
          field: "Principal.Name",
          title: "負責人姓名",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入負責人姓名" }
          }
        },
        {
          field: "Address",
          title: "地址",
          span: 24,
          slots: { default: "column-address" }
        },
        {
          field: "ContactPerson.Name",
          title: "聯絡人",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入聯絡人姓名" }
          }
        },
        {
          field: "Email",
          title: "Email",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入聯絡Email" }
          }
        },
        {
          field: "Phone",
          title: "聯絡電話",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入聯絡電話" }
          }
        },
        {
          field: "Fax",
          title: "傳真",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "text", placeholder: "請輸入傳真號碼" }
          }
        },
        {
          field: "Longitude",
          title: "經度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入經度", clearable: true, type: "number" }
          }
        },
        {
          field: "Latitude",
          title: "緯度",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入緯度", clearable: true, type: "number" }
          }
        },
        {
          field: "Published",
          title: "啟用",
          span: 12,

          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "CommissionBase",
          title: "佣金比例(%)",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入比例", clearable: true, type: "number", min: 0, max: 100, step: 1 }
          }
        },
        {
          field: "ShortUrl",
          title: "Qrcode",
          span: 24,
          slots: { default: "column-qrcode" }
        },
      ],
      rules: {
        Name: [{ required: true }],
        AreaId: [{ required: true }],
        CategoryId: [{ required: true }]
      }
    };

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition) {
            params.condition.and("Type", Operator.Equal, 2);
          }
          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      }
    };

    const areaIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      transfer: true,
      rowId: "Id",
      placeholder: "選擇風景區",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("area/find", value), // eslint-disable-line
        query: params => {
          return model!.dispatch("area/query", params); // eslint-disable-line
        }
      }
    };

    const editor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          // 'htmlEmbed',
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    return {
      grid,
      panelVisible,
      gridOptions,
      formOptions,
      categoryIdSelectOptions,
      areaIdSelectOptions,
      editor,
      editorConfig
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridAdd(row: any, callback: any) {
      row.Published = false;
      row.Ordinal = 0;
      row.CommissionBase = 0;
      row.Principal = { Name: "" };
      row.ContactPerson = { Name: "" };
      row.Introduction = { Content: "" };
      row.Content = { Content: "" };
      callback();
    }
  }
});
